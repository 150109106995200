<template>
  <label class="d-flex align-items-center form-label mb-3">
    Please choose a Phase
  </label>
  <div class="row mb-2" data-kt-buttons="true">
    <div class="col" v-for="(val, key) in modules" :key="key">
      <input
        type="radio"
        class="btn-check"
        name="step_rdn_button"
        :value="key"
        :id="key"
        v-model="step"
        v-on:change="onChangeStep"
      />
      <label
        class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
        :for="key"
      >
        <span class="fw-bolder fs-3">Phase {{ key }}</span>
      </label>
    </div>
  </div>
  <div v-show="step">
    <div class="separator my-2"></div>
    <div class="d-flex align-items-center form-label mb-3">
      Please choose a Module
    </div>
    <select class="form-select" aria-label="Select example" v-model="module">
      <option :value="val" v-for="(val, key) in modules[step]" :key="key">
        {{ key }}. {{ val }}
      </option>
    </select>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "course-module-picker",
  emits: ["stepValueChange", "moduleValueChange"],
  setup(_props, context) {
    const step = ref(""); // The Phase (e.g Phase 1)
    const module = ref(""); // A Phase's module e.g (1. The Vehicule)

    const phaseNames = [
      "Prerequisites for a Learner's License",
      "Guided Driving",
      "Semi-Guided Driving",
      "Semi-Guided to Independent Driving",
    ];

    const modules = {
      1: {
        1: "The Vehicule",
        2: "The Driver",
        3: "The Environnement",
        4: "At-Risk Behaviours",
        5: "Evaluation",
      },
      2: {
        6: "Accompanied Driving",
        7: "OEA Strategy",
      },
      3: {
        8: "Speed",
        9: "Sharing the Road",
        10: "Alcohol and Drugs",
      },
      4: {
        11: "Fatigue and Distractions",
        12: "Eco-Driving",
      },
    };

    watch(step, (_newStep, _prevStep) => {
      context.emit("stepValueChange", step.value);
    });

    watch(module, (_newModule, _prevModule) => {
      context.emit("moduleValueChange", module.value);
    });

    const onChangeStep = () => {
      module.value = Object.values(modules[step.value])[0];
    };

    return {
      step,
      module,
      modules,
      phaseNames,
      onChangeStep,
    };
  },
});
</script>
