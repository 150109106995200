
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "upload-image",
  emits: ["fileChange"],
  setup(props, context) {
    const DEFAULT_MAX_FILE_SIZE = 5000000;
    const dataURL = ref<string>("");
    const uploadedFile = ref<File | null>();
    const removeLabel = ref<boolean>(false);
    const fileName = ref<string>("");
    const imageInputFile = ref<HTMLInputElement | null>(null);

    watch(uploadedFile, (_newFile, _prevFile) => {
      context.emit("fileChange", uploadedFile.value);
    });

    const onChangeFile = (event: Event) => {
      const evenTarget = event.target as HTMLInputElement;
      const file = evenTarget.files?.[0];

      if (!file) return;
      if (!(file.size <= DEFAULT_MAX_FILE_SIZE)) return;

      uploadedFile.value = file;
      fileName.value = file.name;

      const reader = new FileReader();
      reader.onload = () => {
        dataURL.value = reader.result as string;
      }

      reader.readAsDataURL(file);
    }

    const onRemoveImage = () => {
      (imageInputFile.value as HTMLInputElement).value = ""
      uploadedFile.value = null;
      dataURL.value = "";
      fileName.value = "";
      removeLabel.value = false;
    }

    return {
      dataURL,
      removeLabel,
      fileName,
      imageInputFile,
      onChangeFile,
      onRemoveImage,
    }
  },
})
