
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { IStudent } from "@/store/modules/StudentModule";
import CourseModulePicker from "../../custom/CourseModulePicker.vue";
import UploadImage from "../../custom/UploadImage.vue";
import { Session } from "@/store/modules/IScheduleClass";

interface Step1 {
  accountType?: string;
}

interface Step2 {
  step?: string;
  module?: string;
  status?: string;
  email?: string;
  fullName: string;
  fullAddress: string;
  postalCode: string;
  city: string;
  phoneNumber?: string;
  permitNumber?: string;
  truckerPackage?: string;
  dob?: Date | undefined;
  file?: File;
  attendances?: Session[] | undefined;
}

interface Step3 {
  scheduledStartDate?: string;
}

interface Step4 {
  paymentMethod?: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

interface Image {
  id: string;
  dataURL: string;
  file: File; 
}

export default defineComponent({
  name: "create-student-modal",
  components: {
    Field,
    ErrorMessage,
    CourseModulePicker,
    UploadImage,
  },
  setup(props) {
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const createAccountModalRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const store = useStore();
    const router = useRouter();

    const truckPackages = store.state.StudentModule.truckPackages;

    onBeforeMount(() => {
      store.dispatch(Actions.GET_TEMPLATE, 1);
    });

    const templateSessions: ComputedRef<Session[]> = computed(() => {
      return store.state.TemplateModule.templateSessions;
    });

    const toggleAttendence = (sessionIndex: number) => {
      templateSessions.value[sessionIndex].attended = templateSessions.value[
        sessionIndex
      ].attended
        ? false
        : true;
    };

    const formData = ref({
      accountType: "",
      step: "",
      truckerPackage: "",
      status: "",
      module: "",
      permitNumber: "",
      fullName: "",
      email: "",
      fullAddress: "",
      dob: undefined,
      phoneNumber: "",
      paymentMethod: "",
      scheduledStartDate: "",
      postalCode: "",
      city: "",
    });

    const file = ref<File | null>();

    const postalCodeRegex =
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
    const phoneRegex =
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

    // const studentTypeFormSchema = Yup.object().shape({
    //   accountType: Yup.string().required().oneOf(["CAR", "TRUCK"], "Student type is required"),
    //   studentTyperadioGroup: Yup.string()
    //     .required()
    //     .oneOf(["CAR", "TRUCK"], "Student type is required"),
    // });

    const studentInformationFormSchema = Yup.object({
      // permitNumber: Yup.string().when('accountType', {
      //   is: (value: string) => value && value === "transfer",
      //   then: Yup.string().required('Permit number is required')
      // }),
      fullName: Yup.string().required("Name is required"),
      fullAddress: Yup.string().required("Address is required"),
      postalCode: Yup.string()
        .required("Postal code is required")
        .matches(postalCodeRegex, "Postal code is not valid")
        .max(6, "Postal code must be exactly 6 digits"),
      city: Yup.string().required("City is required"),
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .matches(phoneRegex, "Phone number is not valid"),
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      // dob: Yup.date().min(Yup.ref("dob"), ({ min }) => console.log("DOB", min)),
    });

    const postalCodeOnBlur = () => {
      formData.value.postalCode = formData.value.postalCode.toUpperCase();
    };

    const appSchemas = [
      Yup.object({}),
      studentInformationFormSchema,
      Yup.object({}),
      Yup.object({}),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return appSchemas[currentStepIndex.value];
    });

    const enableContinueButton = async () => {
      return await appSchemas[currentStepIndex.value].isValid(formData.value);
    };

    const enableSubmitButton = computed(() => {
      return (
        currentStepIndex.value ===
        (_stepperObj.value?.totatStepsNumber
          ? _stepperObj.value?.totatStepsNumber - 1
          : -1)
      );
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
    });

    const clickedCar = () => {
      if (_stepperObj.value) {
        _stepperObj.value.totatStepsNumber = 4;
      }
    };

    const clickedTruck = () => {
      if (_stepperObj.value) {
        _stepperObj.value.totatStepsNumber = 6;
      }
    };

    const clickedCarTransfer = () => {
      if (_stepperObj.value) {
        _stepperObj.value.totatStepsNumber = 6;
      }
    };

    const clickedNew = () => {
      if (_stepperObj.value) {
        _stepperObj.value.totatStepsNumber = 4;
      }
    };

    // function formatDate(date) {
    //   return new Date(date).toLocaleDateString();
    // }

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }

      const isLastStep = currentStepIndex.value === (_stepperObj.value?.totatStepsNumber
          ? _stepperObj.value?.totatStepsNumber - 1
          : -1);

      if (isLastStep) {
        return;
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    // to save a list of attendances, all the required sessions must be attended, else return undefined
    const generateAttendances = (sessions: Session[]) => {
      const selectedSessions = sessions.filter((session) => session.attended);

      const requiredSessions = sessions.reduce((required, session) => {
        if (session.required) required[session.name] = true;
        return required;
      }, {});

      const attendances = selectedSessions.map((session) => {
        return { session: session, attended: true };
      });

      attendances.forEach((attendance) => {
        delete requiredSessions[attendance.session.name];
        delete attendance.session.attended;
      });

      return attendances.length > 0 &&
        Object.keys(requiredSessions).length === 0
        ? attendances
        : undefined;
    };

    const formSubmit = () => {
      store
        .dispatch(Actions.ADD_STUDENT, {
          student: {
            ...formData.value,
            file: file.value,
            attendances: generateAttendances(templateSessions.value),
          },
        })
        .then((student) => {
          // update the state selectedStudent to the newly created student
          updateSelectedStudent(student);
          Swal.fire({
            text: "Student has been created successfully!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Continue",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            // Close modal after pressing confirm button
            resetForm();
            store.commit(Mutations.RESET_TEMPLATE_SESSIONS);
            const closeButton: any = document.querySelector(
              "#kt_modal_create_student_cancel"
            );
            if (closeButton) closeButton.click();

            // reditect user to studentDetails page
            router.push({ name: "apps-students-details" });

            var body: any = document.querySelector("#kt_body");
            body.style.overflow = "auto";
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const updateSelectedStudent = (student: IStudent) => {
      store.commit(Mutations.SET_SELECTED_STUDENT, student);
    };

    const stepValueChange = (newStepValue: string) => {
      formData.value.step = newStepValue;
    };

    const moduleValueChange = (newModuleValue: string) => {
      formData.value.module = newModuleValue;
    };

    const fileChange = (newFile: File) => {
      file.value = newFile;
    };

    return {
      createAccountRef,
      totalSteps,
      previousStep,
      handleStep,
      formSubmit,
      currentStepIndex,
      formData,
      createAccountModalRef,
      updateSelectedStudent,
      clickedTruck,
      clickedCarTransfer,
      clickedCar,
      clickedNew,
      truckPackages,
      enableContinueButton,
      enableSubmitButton,
      stepValueChange,
      moduleValueChange,
      postalCodeOnBlur,
      toggleAttendence,
      templateSessions,
      fileChange,
    };
  },
});
